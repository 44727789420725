<template>
  <div class="Site PageBox">
    <van-nav-bar
      fixed
      :border="false"
      :title="$t('wallet.default[13]')"
      left-arrow
      @click-left="$router.go(-1)"
    />
    <div class="ScrollBox">
      <img width="350" :src="`./static/images/busup.png`" style="margin-bottom: 20px" />
      <div style="width: 100%;text-align: center">{{$t('wallet.default[14]')}}</div>
    </div>
    <van-dialog v-model="showNotice"  :title="$t('busAuth2[0]')" @confirm="gotoBusAuth"
                :show-cancel-button="true" :show-confirm-button="true" :confirmButtonText="$t('busAuth2[1]')"
                :cancel-button-text="$t('common[3]')" style="width: 96%" >
      <div class="ScrollBox" style="height:300px"
           @click="$router.push(`/article/help/${helpid}`)">
          <dd v-html="content"></dd>
      </div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  name: 'Help',
  components: {
  },
  props: [],
  data() {
    return {
      showCon: false,
      showNotice: false,
      helpid:0,
      content:'',
      infoData: '',
    }
  },
  computed: {

  },
  watch: {

  },
  created() {
	  // let aa=JSON.parse(this.InitData.helpList[0].content)
	  // this.InitData.helpList.map(item=>JSON.parse(item.content))
    if (this.InitData.helpList.length>0){
      this.showNotice = true;
    }
    if (this.InitData.helpList.length>0){
      this.helpid = this.InitData.helpList[0].id;
      this.content = this.InitData.helpList[0].content;
    }
	console.log(this.InitData.helpList)
  },
  mounted() {

  },
  activated() {

  },
  destroyed() {

  },
  methods: {
    openShow(data) {
      this.showCon = true
      this.infoData = data
    },
    gotoBusAuth(){
      let info  = this.UserInfo;
      if (info.bus_deposit>0&&info.bus_amount>0){
        this.$router.push("/busauth");
      }
    }
  }
}
</script>
<style scoped>
.Content>>>img{
  max-width: 100%;
}
.van-cell__title{
  font-size: 14px;
  color: #000;
}
.PageBox {
  color: #d7d7d7;
  background-color: #13171A;
}

.van-nav-bar {
  background-color: #191C23;
}
.van-nav-bar>>>.van-nav-bar__title{
  color:#fff;
  font-weight: bold;
}
.van-nav-bar>>>.van-nav-bar__arrow {
  color:#fff;
}

.ScrollBox{
	padding:0 35px;
  margin-top: 80px;
}
.ScrollBox>>>.van-cell{
	padding:20px 5px;
	border-bottom: 1px solid #D9D9D9;
}
.ScrollBox>>>.van-cell:last-child{
	border-bottom: 0px solid #D9D9D9;
}
.ScrollBox>>>.van-cell::after{
	display: none;
}
.ScrollBox>>>.van-icon-chat-o::before{
	content: '';
	border-radius: 50%;
	background: #B3B5FC;
	width:8px;
	height: 8px;
}
.ScrollBox>>>.van-icon-chat-o{
	display: flex;
	align-items: center;
	justify-content: center;
}
.ScrollBox>>>.van-cell__title{
	font-size: 16px;
	font-family: ui-serif;
}
.ScrollBox>>>h3{
	font-size: 1.05rem;
	font-family: emoji;
}
.van-cell>>>.van-icon{
	font-size: 18px;
	color:#333;
}
.PageBox>>> .van-dialog {
  background-color: #191C23;
  color: #d7d7d7;
}
.van-dialog>>>.van-field__control {
  background-color: #13171A;
  color: #fff;
}

.van-dialog>>>.van-cell {
  background-color: #191C23;
  color: #fff;
}

.PageBox>>> .van-dialog__confirm{
  background-color: #191C23;
  color:#0076FA;
}
.PageBox>>> .van-dialog__cancel{
  background-color: #191C23;
  color: #fff;
}
.PageBox>>>.van-popup__close-icon--top-right{
	color:#333;
	top:20px;
	right: 20px;
}
.PageBox>>>.Content{
	margin-top:40px;
	padding:0 20px;
}
.PageBox>>>.Content p{
	font-size: 13px;
	line-height: 1.7;
	font-family: 'vant-icon';
	font-weight: 400;
	color: #666666;
}

</style>
